import { Wrapper } from './style';
import { SpinnerDotted } from 'spinners-react';

export interface ILoaderProps {
  isLoading: boolean;
}

const Loader = ({ isLoading }: ILoaderProps) => {
  return (
    <Wrapper>
      <SpinnerDotted size={90} thickness={180} speed={72} color="rgba(24, 61, 110, 0.7)" enabled={isLoading} />
    </Wrapper>
  );
};
export default Loader;
