import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div(() => {
  const out = [
    tw`absolute`,
    {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  ];

  return out;
});

export const Image = styled.img(() => tw`w-full`);
